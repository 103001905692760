<template>
    <div class="modal-mask">
        <div class="modal-container">
            <div class="seller-header">
                {{ title }}
                <span class="material-icons seller-header-close" v-on:click="close">cancel</span>
            </div>
            <div style="max-height: 95vh; overflow-y: auto;">
                <cc-loader v-if="loading && !current_seller"/>

                <div v-if="current_seller && VIEW == 'SELLER_FORM'">
                    <SellerForm ref="seller-form" @close="current_seller = null; VIEW = 'SELLER_LIST'" :profiles="profiles"
                        @reload=" () => !seller_id ? load() : $emit('reload') " :seller="current_seller"
                        :close="() => { current_seller = null; VIEW = 'SELLER_LIST' }" />
                </div>

                <div v-else-if=" VIEW == 'SELLER_PARTICIPATION' ">
                    <cc-button style="width: auto !important; margin-left: 90% !important; margin-top: 3vh;"
                        v-if=" VIEW == 'SELLER_FORM' " :nolink=" true " :content=" 'Salvar' " @click=" save_seller "
                        :classes=" 'thin ml-2 theme-2 fixed' " />
                    <SellerParticipation :request_seller=" { seller: current_seller } " />
                </div>

                <div v-else-if=" current_seller " style="margin-top: 2vh;">
                    <SellerClients @close=" current_seller = null; VIEW == 'SELLER_LIST' "
                        v-show=" current_seller && VIEW == 'SELLER_CLIENTS' " :seller=" current_seller " />
                </div>

                <div v-else-if=" !loading " class="selller-body">

                    <div>
                        <div class="seller-text-title">{{provider.name}}</div>
                        <div class="seller-inputs-container">
                            <div class="seller-inputs-container-name">
                                <StandardInput class="seller-input-name seller-inputs-button-size" :title=" 'Nome' "
                                    :action=" setFilterName " :type=" 'text' " />
                                <StandardButton class="seller-inputs-button-size" :action=" () => { } " :text=" 'Buscar' "
                                    :iconName=" 'search' " />
                            </div>
                            <div class="seller-inputs-container-buttons">
                                <StandardButton class="seller-inputs-button-size" :action=" close " :text=" 'Cancelar' " />
                                <StandardButton class="seller-inputs-button-size" :action=" add " :text=" 'Adicionar' " />
                            </div>
                        </div>
                        <div class="seller-table-header desktop">
                            <div class="seller-table-name-with">Nome</div>
                            <div class="seller-table-login-with">Login</div>
                            <div class="seller-table-phone-with">Telefone</div>
                            <div class="seller-table-password-with">Senha</div>
                            <div class="seller-table-share-with">Participação</div>
                            <div class="seller-table-date-with">Criado em</div>
                            <div class="seller-table-actions-with" style="text-align: center;">Ações</div>
                        </div>
                        <div class="seller-table-row" v-for="( seller, idx ) in  sellers " :key=" seller.id "
                            :class=" getSpecialBackground(idx) ">
                            <div class="seller-table-name-with">
                                <div class="seller-table-header-mobile">Nome</div>{{ seller.name }}
                            </div>
                            <div class="seller-table-login-with">
                                <div class="seller-table-header-mobile">Login</div>{{ seller.login }}
                            </div>
                            <div class="seller-table-phone-with">
                                <div class="seller-table-header-mobile">Telefone</div>{{seller.phone}}
                            </div>
                            <div class="seller-table-password-with">
                                <!-- <div class="seller-table-header-mobile">Senha</div>
                              {{ seller.password }} -->
                                <span @click=" showPasswordModal(seller) " class="material-icons-outlined password-icon"
                                    title="Ver Senha">
                                    lock
                                </span>
                            </div>
                            <div class="seller-table-share-with">
                                <div class="seller-table-header-mobile">Participação</div>
                                <div :class=" getParticipation(seller.participation) ">
                                    {{ (seller.participation*100.00).toFixed(2) }} %
                                </div>
                            </div>
                            <div class="seller-table-date-with">
                                <div class="seller-table-header-mobile">Criado em</div>{{ seller.created_at | formatDate }}
                            </div>
                            <div class="seller-table-actions-with" style="display: flex;">
                                <div class="seller-table-header-mobile">Ações</div>
                                <span @click=" show_participations(seller) "
                                    class="material-icons-outlined seller-table-history-icon"
                                    title="Histórico de Cotações">event_note</span>
                                <span @click=" edit(seller) " class="material-icons-outlined seller-table-edit-icon"
                                    title="Editar Vendedor">edit</span>
                                <span @click=" show_clients(seller) "
                                    class="material-icons-outlined seller-table-clients-icon" title="Clientes">people</span>
                                <span class="material-icons-outlined seller-table-delete-icon"
                                    @click=" remove_seller(seller) " title="Remover vendedor">delete</span>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>

                <cc-pagination classes="orange justify-end" :items_by_page_value=" items_by_page_value "
                            :total=" total_items " :page=" page " v-on:change=" updatePage($event) "></cc-pagination>
            </div>
        </div>
        <SellersLoginModal v-if=" isPasswordModalOpen " @close=" isPasswordModalOpen = false " :sellerInfo=" sellerInfo " />
    </div>
</template>

<script>
import ProviderService from '@/services/v3/provider.service'
import SellerService from '@/services/v3/providers/seller.service'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import Pagination from "@/components/cliente/base-components/Pagination";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerForm from "./_seller.form.vue"
import SellerClients from "./_seller.clients.vue"
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import SellerParticipation from '@/modals/request-providers/components/seller-participation.partial.vue'
import StandardButton from '../../../components/ui/buttons/StandardButton.vue';
import SellersLoginModal from './sellers-password-modal.vue';
import Paginacao from "@/components/cliente/base-components/Pagination";

export default {
    mixins: [loaderMixin],
    props: ["provider", "seller_id", "to_clients"],
    components: {
        SellerParticipation,
        SellerForm,
        SellerClients,
        Pagination,
        StandardInput,
        StandardButton,
        SellersLoginModal,
        ccPagination: Paginacao
    },
    data() {
        return {
            svc: new ProviderService(),
            seller_svc: new SellerService(),
            //sellers: null,
            loading: true,
            filters: {},
            profiles: [],
            current_seller: null,
            is_edit_seller_open: false,
            VIEW: "SELLER_LIST",
            is_list_seller_clients_open: false,
            total_items: 0,
            items_by_page_value: 100,
            page: 1,
            sellers: [],
            title: 'Vendedores',
            isPasswordModalOpen: false,
            sellerInfo: undefined,
        }
    },
    watch: {
        current_seller: function () {
            if (this.current_seller == null) {
                this.update_modal({ title: "Vendedores" })
            }
        },
    },
    methods: {
        showPasswordModal(seller) {
            this.sellerInfo = seller;
            this.isPasswordModalOpen = true;
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        setFilterName(value) {
            this.filters.name = value
            this.page = 1
            this.load()
        },
        getParticipation(value) {
            if (value <= 0.3) {
                return 'participation-low'
            } else if (value > 0.7) {
                return 'participation-high'
            } else {
                return 'participation-medium'
            }
        },
        show_participations(seller) {
            this.VIEW = 'SELLER_PARTICIPATION'
            this.current_seller = seller
        },
        remove_seller(seller) {
            this.confirm_action({
                message: `Confirma remoção do vendedor ${seller.name}?`,
                callback: () => this.remove(seller.id)
            })
        },
        remove(seller_id) {
            return this.seller_svc.remove(seller_id).then(() => this.load())
        },
        update_modal(new_settings) {
            this.modal = { ...this.modal, ...new_settings }
        },
        edit(seller) {
            this.VIEW = 'SELLER_FORM'
            this.current_seller = { ...seller, provider: this.provider }
            this.update_modal({ title: `${seller.name}` })
        },
        add() {
            this.VIEW = 'SELLER_FORM'
            this.current_seller = { provider: this.provider }
            this.update_modal({ title: `Novo Vendedor` })
        },
        show_clients(seller) {
            this.VIEW = 'SELLER_CLIENTS'
            this.current_seller = { ...seller, provider: this.provider }
            this.update_modal({ title: `${seller.name}` })
        },
        toggle_status(seller) {
            this.seller_svc.update_status(seller.id, { status: !seller.status }).then(resp => {
                seller.status = !seller.status
            })
        },
        load_profiles() {
            return this.svc.load_profiles(this.provider.id).then(response => response.data).then(data => {
                this.profiles = data.data
                this.profiles.push({
                    name: "Vendedor Padrão",
                    id: 753
                })
                this.profiles.push({
                    name: "Fornecedor Master",
                    id: 1322
                })
            })
        },
        close() {
            this.$emit('close')
        },
        save_seller(evt) {
            evt.preventDefault();
            this.$refs['seller-form'].$refs['send-seller'].click()
        },
        load(page = this.page) {
            this.loading = true
            this.page = page
            return this.svc.load_sellers(this.provider.id, {
                page: this.page,
                name: this.filters.name,
                page_size: this.items_by_page_value
            }).then(response => response.data).then((data) => {
                this.sellers = data.data
                this.$forceUpdate()
                setTimeout(() => { this.total_items = data.meta.total }, 500);
                this.loading = false
            }).catch((error) => {
                ErrorHandlerService.handle(error, this.$store);
                this.loading = false
            })
        },
        async updatePage (event) {
            this.loading = true
            await this.load(event)
            this.loading = false
        }
    },
    async created() {
        await this.load()
        await this.load_profiles()
        if (this.seller_id) {
            console.log('entrou');
            await this.seller_svc.show(this.seller_id).then(response => {
                this.current_seller = response.data
                if (this.to_clients) {
                    this.VIEW = 'SELLER_CLIENTS'
                    this.title = 'Clientes'
                } else {
                    this.VIEW = 'SELLER_FORM'
                    this.title = 'Editar vendedor'
                }
                this.loading = false
            }).catch((error) => {
                ErrorHandlerService.handle(error, this.$store);
                this.loading = false
            })
        } else this.loading = false
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/commons/_variables';

#sellers-content {
    height: 50vh;
}

// /deep/.modal-body {
//     padding-bottom: 10rem;
// }
.change-status {
    display: flex;
    align-items: center;
    min-width: 15rem;

    .success-update {
        margin-left: 5px;
        visibility: hidden;
        color: $brand-success;

        &.visibile {
            visibility: 'visible';
        }
    }
}

.actions {
    display: flex;
    border: none;

    a {
        margin-right: 5px !important;
    }
}

.canceled {
    i {
        color: $brand-danger;
    }
}

.waiting {
    i {
        color: $yellow2;
    }
}

.confirmed {
    i {
        color: $ternary-color;
    }
}

.error {
    position: absolute;
    color: $lower-red;
}

i.remove-btn {
    color: $lower-red !important;
    font-size: 15px;
    cursor: pointer;
}

.filters {
    padding: 10px 15px;
}

.password-icon {
    cursor: pointer;
    color: #FF7110;
    margin-left: 10px;
}

/deep/ .cc-loader img {
    height: 5rem;
}

/deep/ .vue-js-switch {
    margin: 0;
}

.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-container {
    width: 110vw;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    margin-top: 15vh;
}

.seller-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FF7110;
    border-radius: 10px 10px 0 0;
    padding: 1vh 1vw;
    font-size: 2em;
    color: white;
}

.seller-header-close {
    cursor: pointer;
    font-size: 1.2em;
}

.selller-body {
    padding: 2em;
}

.seller-text-title {
    font-size: 1.5em;
    color: #FF7110;
    margin-bottom: 3vh;
}

.seller-inputs-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 3vh;
}

.seller-input-name {
    width: 65%;
}

.seller-inputs-container-name {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.seller-inputs-container-buttons {
    width: 28%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.seller-table-header {
    display: flex;
    padding: 1vh 1vw;
    font-weight: 600;
    font-size: 1.5em;
    color: #605F5F;
    border-bottom: 1px solid lightgray;
}

.seller-table-name-with {
    width: 15%;
}

.seller-table-login-with {
    flex: 1
}

.seller-table-phone-with {
    width: 12%;
}

.seller-table-password-with {
    width: 10%;
}

.seller-table-share-with {
    width: 15%;
}

.seller-table-date-with {
    width: 12%;
}

.seller-table-actions-with {
    width: 10%;
}

.seller-table-row {
    display: flex;
    align-items: center;
    padding: 2vh 1vw;
    font-weight: 400;
    font-size: 1em;
    color: #605F5F;
}

.participation-high {
    padding: 0.5vh 1vw;
    border: 1px solid #278E3F;
    width: fit-content;
    color: #278E3F;
    background-color: rgba(42, 205, 114, 0.1);
    border-radius: 10px;
}

.participation-medium {
    width: fit-content;
    padding: 0.5vh 1vw;
    border: 1px solid #FF7110;
    color: #FF7110;
    border-radius: 10px;
    background-color: rgba(255, 113, 16, 0.15);
}

.participation-low {
    padding: 0.5vh 1vw;
    border: 1px solid #E63535;
    width: fit-content;
    color: #E63535;
    border-radius: 10px;
    background-color: rgba(230, 53, 53, 0.1);
}

.seller-table-history-icon {
    cursor: pointer;
    color: #FF9954;
    font-size: 1.7em;
    margin-right: 0.5vw;
}

.seller-table-edit-icon {
    cursor: pointer;
    color: #606060;
    margin-right: 0.5vw;
    font-size: 1.7em;
}

.seller-table-clients-icon {
    cursor: pointer;
    margin-right: 0.5vw;
    color: #17A2B8;
    font-size: 1.7em;
}

.seller-table-delete-icon {
    cursor: pointer;
    margin-right: 0.5vw;
    color: #CB4243;
    font-size: 1.7em;
}

.page-table-line-special {
    background: #F7F7F7;
}

.seller-table-header-mobile {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .modal-mask {
        font-size: 1.5vw;
    }

    .seller-table-actions-with {
        width: 12%;
    }
}

@media only screen and (max-width: 600px) {
    .modal-mask {
        font-size: 3.5vw;
    }

    .desktop {
        display: none;
    }

    .seller-table-row {
        flex-direction: column;
        border-bottom: 1px solid lightgray;
    }

    .seller-table-header-mobile {
        display: flex;
        flex: 1;
        font-weight: 600;
        font-size: 1.3em;
        color: #605F5F;
    }

    .seller-table-name-with {
        width: 100%;
        display: flex;
    }

    .seller-table-login-with {
        width: 100%;
        display: flex;
    }

    .seller-table-phone-with {
        width: 100%;
        display: flex;
    }

    .seller-table-password-with {
        width: 100%;
        display: flex;
    }

    .seller-table-share-with {
        width: 100%;
        display: flex;
    }

    .seller-table-date-with {
        width: 100%;
        display: flex;
    }

    .seller-table-actions-with {
        width: 100%;
        display: flex;
    }

    .seller-inputs-container {
        flex-direction: column;
    }

    .seller-inputs-container-buttons {
        width: 100%;
    }

    .seller-inputs-button-size {
        width: 45%;
    }

    .seller-inputs-container-name {
        width: 100%;
        margin-bottom: 3vh;
    }

    .seller-table-history-icon {
        font-size: 2em;
        margin-right: 2vw;
    }

    .seller-table-edit-icon {
        font-size: 2em;
        margin-right: 2vw;
    }

    .seller-table-clients-icon {
        font-size: 2em;
        margin-right: 2vw;
    }

    .seller-table-delete-icon {
        font-size: 2em;
        margin-right: 2vw;
    }

    .modal-container {
        width: 115vw;
    }
}</style>
